import { Box, Typography, useTheme } from "@mui/material";
import { CustomTextArea } from "../../CustomTextArea/CustomTextArea";
import { ReactChildren, ReactNode } from "react";
import { CustomTextField } from "../../CustomTextField/CustomTextField";

interface CustomFieldProps {
  name: string;
  value: any;
  error: any;
  touched: any;
  type?: string;
  onBlur?: () => void;
  handleChange: () => void;
  children: ReactNode;
  id?: any;
}

export const CustomField = ({
  name,
  value,
  error,
  touched,
  onBlur,
  type = "text",
  handleChange,
  children,
  id,
}: CustomFieldProps) => {
  const { palette } = useTheme();

  return (
    <Box>
      <Typography
        variant="body1"
        mb={2}
        fontSize="0.875rem"
        fontWeight={500}
        color={palette.common.black}
      >
        {children}
      </Typography>

      <CustomTextField
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        error={error}
        touched={touched}
        type={type}
        id={id}
      />
    </Box>
  );
};
