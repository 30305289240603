import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { AppContext } from "../../App";
import { SideNavigationPage } from "../SideNavigationPage/SideNavigationPage";
import { useQuery } from "@apollo/client";
import { GetProjectDocument } from "../../generated";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceTab } from "./ServiceTab/ServiceTab";
import { AddTransformation } from "./Transformation/AddTransformation";
import { includes } from "lodash";
import { AddConnections } from "./Connections/AddConnections";

export const Services = ({ title, query }: { title: string; query: any }) => {
  const navigate = useNavigate();

  const [openAdd, setOpenAdd] = useState(false);
  const { projectId, workspaceId } = useParams();
  const { palette } = useTheme();

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const workspace = data?.workspace;

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            innerSideBar
            leftHeaderElements={<Typography variant="h5">{title}</Typography>}
            // verticalNav={
            //   <DataServiceNav
            //     show={showSidenav}
            //     workspaceName={workspace?.name || ""}
            //     setShowSidenav={setShowSidenav}
            //   />
            // }
            rightHeaderElements={<></>}
            content={<ServiceTab query={query} />}
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
