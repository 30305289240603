import React from "react";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore, Logout, SyncAlt } from "@mui/icons-material";
import { useApolloClient } from "@apollo/client";

import { MenuItem } from "../Menu/MenuItem";
import { Menu } from "../Menu/Menu";
import { Typography } from "../Typography/Typography";
import { Link } from "../Link/Link";
import { useNavigate } from "react-router-dom";
import { WorkspaceSwitcherDialog } from "../../WorkspaceSwitcher/WorkspaceSwitcherDialog";
import ProfileAvatarIcon from "../ProfileAvatarIcon/ProfileAvatarIcon";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import {
  MenuDropdown,
  MenuDropdownItem,
  NavbarMenuWrapper,
  ProfileName,
  NavHeading,
} from "./style";
import { WorkspaceRoleEnum } from "../../generated";
import { MembersIcon } from "../Icons/MembersIcon";

export interface NavbarMenuProps {
  username: string;
  emailAddress?: string;
  multipleWorkspaces: boolean;
}

export const NavbarMenu = ({
  username,
  emailAddress,
  multipleWorkspaces,
}: NavbarMenuProps) => {
  const client = useApolloClient();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { workspaceId } = useWorkspaceId();
  const { accessible, loading, role } = useGetUserRole(workspaceId);

  const [workspaceSwitcherVisible, showWorkspaceSwitcher] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onWorkspaceSwitcher = () => {
    showWorkspaceSwitcher(true);
    handleClose();
  };
  const onClickMyOrganization = () => {};

  const onClickMyProfile = () => {
    navigate(`/workspace/${workspaceId}/my-profile`);
    handleClose();
  };

  const onClickMembers = () => {
    navigate(`/workspace/${workspaceId}/members`);
    handleClose();
  };

  const onClickLogout = () => {
    handleClose();
    client.resetStore();
    localStorage.setItem("token", "");
    window.location.href = "/login";
  };

  const lastMenuItemRef = React.createRef<HTMLLIElement>();

  const onKeyDownMenu = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !menuOpen &&
      (event.code === "Enter" ||
        event.code === "Space" ||
        event.code === "ArrowDown")
    ) {
      setAnchorEl(event.currentTarget);
    } else if (!menuOpen && event.code === "ArrowUp") {
      setAnchorEl(event.currentTarget);
      lastMenuItemRef?.current?.focus();
    }
  };

  return (
    <>
      <WorkspaceSwitcherDialog
        open={workspaceSwitcherVisible}
        onClose={() => showWorkspaceSwitcher(false)}
      />
      <NavbarMenuWrapper
        id="nav-menu"
        tabIndex={1}
        role="combobox"
        aria-label="organization profile, and user profile"
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
        onKeyDown={onKeyDownMenu}
      >
        <ProfileAvatarIcon
          email={emailAddress}
          name={username}
          width={28}
          height={28}
          className="profileAvatarIcon"
        />
        <ProfileName variant="body3">{username}</ProfileName>
        {menuOpen ? (
          <ExpandLess
            sx={{
              color: palette.gray.dark,
              width: "0.875rem",
              height: "0.875rem",

              "@media (min-width: 1200px)": {
                width: "1rem",
                height: "1rem",
              },
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              color: palette.gray.dark,
              width: "0.875rem",
              height: "0.875rem",

              "@media (min-width: 1200px)": {
                width: "1rem",
                height: "1rem",
              },
            }}
          />
        )}
      </NavbarMenuWrapper>
      {!loading && (
        <MenuDropdown
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "nav-menu",
            sx: {
              width: "13.5rem",
            },
          }}
        >
          {accessible && (
            <MenuDropdownItem onClick={onClickMyOrganization}>
              <Link
                variant="body2"
                removeStyling
                to={`/workspace/${workspaceId}/my-organization`}
              >
                My Organization
              </Link>
            </MenuDropdownItem>
          )}
          <MenuDropdownItem onClick={onClickMyProfile}>
            <Link
              variant="body2"
              removeStyling
              to={`/workspace/${workspaceId}/my-profile`}
            >
              My Profile
            </Link>
          </MenuDropdownItem>
          {accessible && (
            <>
              {multipleWorkspaces && [
                <Divider key="workspaceDivider" sx={{ my: 0.5 }} />,
                <MenuDropdownItem
                  key="workspaceMenuItem"
                  onClick={onWorkspaceSwitcher}
                >
                  <ListItemText>Switch Workspaces</ListItemText>
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <SyncAlt fontSize="small" />
                  </ListItemIcon>
                </MenuDropdownItem>,
              ]}
              {role !== WorkspaceRoleEnum.Viewer && [
                <NavHeading>
                  <Typography variant="body2">Account Admin</Typography>
                </NavHeading>,
                <MenuDropdownItem onClick={onClickMembers}>
                  <Link
                    variant="body2"
                    removeStyling
                    to={`/workspace/${workspaceId}/members`}
                  >
                    <MembersIcon />
                    Members
                  </Link>
                </MenuDropdownItem>,
              ]}
              <Divider sx={{ my: 0.5 }} />
            </>
          )}

          <MenuDropdownItem ref={lastMenuItemRef} onClick={onClickLogout}>
            <ListItemText>Log Out</ListItemText>
            <ListItemIcon sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Logout fontSize="small" />
            </ListItemIcon>
          </MenuDropdownItem>
        </MenuDropdown>
      )}
    </>
  );
};
