import { useState } from "react";
import {
  GetCurrentUserInfoDocument,
  UpdateUserDetailsDocument,
} from "../../generated";
import { useMutation } from "@apollo/client";
import { displayToast } from "../../common/Toast/toast";
import { UserDetailsType } from "../MyProfilePage";
import { FormikHelpers } from "formik";

const useUserDetails = () => {
  const [toggleDetailsEdit, setToggleDetailsEdit] = useState(false);
  const [update, { loading }] = useMutation(UpdateUserDetailsDocument, {
    refetchQueries: [GetCurrentUserInfoDocument],
  });

  const handleToggleDetailsEdit = (toggle: boolean) => {
    setToggleDetailsEdit(toggle);
  };

  const submitUserDetails = async (
    values: UserDetailsType,
    formikHelpers: FormikHelpers<UserDetailsType>
  ) => {
    try {
      const firstName = values?.firstName;
      const lastName = values?.lastName;
      await update({
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
      });
      handleToggleDetailsEdit(false);
      displayToast("Updated Details Successfully");
    } catch (err) {
      displayToast("Something went wrong", {}, "error");
      console.log(err);
    }
  };

  return {
    toggleDetailsEdit,
    handleToggleDetailsEdit,
    submitUserDetails,
    loadingMutateDetails: loading,
  };
};

export default useUserDetails;
