import { useState } from "react";
import { Button } from "../../Button/Button";
import { Box, Typography, useTheme } from "@mui/material";
import { CustomTextField } from "../../CustomTextField/CustomTextField";
import { CustomTextArea } from "../../CustomTextArea/CustomTextArea";
import { OpenInBrowser } from "@mui/icons-material";
import {
  SubHeading,
  HeadTitle,
  FieldsHolder,
  FieldsSet,
  Label,
} from "../style";

export const Fields = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}: any) => {
  const { palette } = useTheme();

  return (
    <>
      <FieldsHolder>
        <HeadTitle>About</HeadTitle>
        <Box display="flex" flexDirection="column">
          <FieldsSet>
            <Label htmlFor="name">
              Transformation Service Name <span className="asterisk">*</span>
            </Label>

            <CustomTextField
              id="name"
              name="name"
              onBlur={handleBlur}
              value={values?.name}
              onChange={handleChange}
              error={errors?.name}
              touched={touched?.name}
            />
          </FieldsSet>
          <FieldsSet>
            <Label htmlFor="description">Transformation Description</Label>

            <CustomTextArea
              id="description"
              name="description"
              onBlur={handleBlur}
              value={values?.description}
              onChange={handleChange}
              error={errors?.description}
              touched={touched?.description}
            />
          </FieldsSet>
          <FieldsSet>
            <Label htmlFor="apiKey">
              API Key <span className="asterisk">*</span>
            </Label>

            <CustomTextField
              id="apiKey"
              name="apiKey"
              onBlur={handleBlur}
              value={values?.apiKey}
              onChange={handleChange}
              error={errors?.apiKey}
              touched={touched?.apiKey}
            />
          </FieldsSet>
          <FieldsSet>
            <Label htmlFor="accountId">
              Account ID <span className="asterisk">*</span>
            </Label>

            <CustomTextField
              id="accountId"
              name="accountId"
              onBlur={handleBlur}
              value={values?.accountId}
              onChange={handleChange}
              error={errors?.accountId}
              touched={touched?.accountId}
            />
          </FieldsSet>
          {/* <FieldsSet>
            <SubHeading className="add">Warehouse service Id</SubHeading>

            <CustomTextField
              name="warehouseServiceId"
              onBlur={handleBlur}
              value={values?.warehouseServiceId}
              onChange={handleChange}
              error={errors?.warehouseServiceId}
              touched={touched?.warehouseServiceId}
            />
          </FieldsSet> */}
        </Box>
      </FieldsHolder>
    </>
  );
};
