import { useMutation } from "@apollo/client";
import { UpdateWorkspaceRoleScopeDocument } from "../../generated";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteIcon } from "../../common/Icons/DeleteIcon";
import { Button } from "@mui/material";
import { useState } from "react";

export const ProjectOverviewDeleteMember = ({ id, refetch }: any) => {
  const { workspaceId, projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [updateRole] = useMutation(
    UpdateWorkspaceRoleScopeDocument
  );

  const deleteMember = (memberId: string) => {
    setLoading(true)
    if (memberId) {
      updateRole({
        variables: {
          input: {
            workspaceId: workspaceId || "",
            userId: memberId || "",
            projectsToAdd: [],
            projectsToRemove: [projectId || ""],
          },
        },
      }).then(async (res) => {
        if (res.data?.updateWorkspaceRoleScope) {
          toast.success("User deleted");
          await refetch();
        } else {
          toast.error("User can't be deleted");
        }
      }).finally(() => setLoading(false))
    }
  };

  return (
    <Button onClick={() => deleteMember(id)} disabled={loading}>
      <DeleteIcon />
    </Button>
  );
};
