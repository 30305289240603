import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";

import { useParams } from "react-router-dom";
import { SimpleLoader } from "../../Loader/Loader";
import { Specification } from "../../../Sources/All/AddSource/Internal/Specification";
import {
  CreateTransformationServiceDocument,
  CreateWarehouseServiceDocument,
  GetCurrentUserInfoDocument,
  GetTransformationServicesDocument,
  GetWarehouseServicesDocument,
  TransformationServiceProvider,
  WarehouseServiceProvider,
} from "../../../generated";
import { Button } from "../../Button/Button";
import { Formik } from "formik";
import { CustomTextField } from "../../CustomTextField/CustomTextField";
import { toast } from "react-toastify";
import { Fields } from "./Fields";
import RightSidePanel from "../../RightSidePanel";
import {
  ButtonPrimary,
  PanelHolder,
  PanelContent,
  PanelTopContent,
  PanelContentHead,
  HeadSmallTitle,
  HeadTitle,
  SubHeading,
  RadioHolder,
  LabelTitle,
  LableDes,
  FormCenter,
  FooterBtnHolder,
} from "../style";
import { Search } from "../Transformation/Search";

const options = [
  {
    title: WarehouseServiceProvider.Redshift.replaceAll("_", " "),
    value: WarehouseServiceProvider.Redshift,
    method: "On Platform",
    owner: "Brighthive",
    description: "Description goes here",
  },
  {
    title: WarehouseServiceProvider.Snowflake.replaceAll("_", " "),
    value: WarehouseServiceProvider.Snowflake,
    method: "On Platform",
    owner: "Brighthive",
    description: "Description goes here",
  },
];

export const AddWarehouse = () => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };
  const { workspaceId } = useParams();
  const { palette } = useTheme();

  const [create, { loading }] = useMutation(CreateWarehouseServiceDocument, {
    refetchQueries: [GetWarehouseServicesDocument],
  });

  const { data } = useQuery(GetCurrentUserInfoDocument);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained">
        Add Warehouse
      </Button>
      {open && (
        <RightSidePanel isOpen={open} onToggle={close}>
          <PanelHolder>
            <Formik
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required"),
                description: Yup.string(),

                accountId: Yup.string().when("provider", {
                  is: WarehouseServiceProvider.Snowflake,
                  then: Yup.string().required("Account ID is required"),
                  otherwise: Yup.string().notRequired(),
                }),
                username: Yup.string().when("provider", {
                  is: WarehouseServiceProvider.Snowflake,
                  then: Yup.string().required("Username is required"),
                  otherwise: Yup.string().notRequired(),
                }),
                password: Yup.string().when("provider", {
                  is: WarehouseServiceProvider.Snowflake,
                  then: Yup.string().required("Password is required"),
                  otherwise: Yup.string().notRequired(),
                }),
              })}
              validateOnBlur={true}
              validateOnChange={true}
              initialValues={{
                name: "",
                description: "",
                transformationMethod: "managed-services",
                provider: "" as any,
                accountId: "",
                username: "",
                password: "",
              }}
              onSubmit={(values) => {
                const {
                  transformationMethod,
                  accountId,
                  username,
                  password,
                  ...restValues
                } = values;

                const configurations =
                  values.provider === WarehouseServiceProvider.Snowflake
                    ? { accountId, username, password }
                    : {};

                create({
                  variables: {
                    input: {
                      workspaceId: workspaceId || "",
                      managed:
                        transformationMethod === "managed-services"
                          ? true
                          : false,
                      configurations: JSON.stringify(configurations),
                      ...restValues,
                    },
                  },
                }).then((res) => {
                  if (res.data?.createWarehouseService.success) {
                    toast.success(
                      "Service created " +
                        res.data?.createWarehouseService.warehouseServiceId
                    );
                  } else {
                    toast.error("Service creation failed...");
                  }
                  close();
                });
              }}
            >
              {({
                handleChange,
                errors,
                touched,
                handleSubmit,
                values,
                handleBlur,
                setFieldValue,
                isValid,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <PanelContent>
                    <PanelTopContent>
                      <PanelContentHead>
                        <HeadSmallTitle>Warehouse</HeadSmallTitle>
                        <HeadTitle>New Warehouse</HeadTitle>
                      </PanelContentHead>

                      {/* <SubHeading>Warehouse Service Type</SubHeading>
                      <RadioHolder>
                        <FormControl>
                           <RadioGroup
                            defaultValue="platform"
                            name="transformationMethod"
                            value={values.transformationMethod}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="managed-services"
                              control={<Radio />}
                              className="formlist"
                              label={
                                <>
                                  <LabelTitle>Managed Service</LabelTitle>
                                  <LableDes>
                                    Securely transform data using engines
                                    private to this workspace. This means your
                                    data never leaves the workspace.
                                  </LableDes>
                                </>
                              }
                            />

                            <FormControlLabel
                            value={"Customer-hosted"}
                            control={<Radio />}
                            label={
                              <>
                                <Typography
                                  variant="body1"
                                  mb={2}
                                  fontSize="1rem"
                                  lineHeight="1"
                                  fontWeight={400}
                                  className="label-title"
                                  color={palette.common.black}
                                >
                                  Customer-hosted
                                </Typography>
                                <Typography
                                  variant="body1"
                                  fontSize="0.875rem"
                                  lineHeight="1.2"
                                  fontWeight={400}
                                  color={palette.gray.darkest}
                                >
                                  Transform data off-platform using external
                                  engines managed by an approved workspace
                                  organization.
                                </Typography>
                              </>
                            }
                          />
                          <FormControlLabel
                            value={"custom-link"}
                            control={<Radio />}
                            label={
                              <>
                                <Typography
                                  variant="body1"
                                  mb={2}
                                  fontSize="1rem"
                                  lineHeight="1"
                                  fontWeight={400}
                                  className="label-title"
                                  color={palette.common.black}
                                >
                                  Custom link
                                </Typography>
                                <Typography
                                  variant="body1"
                                  fontSize="0.875rem"
                                  lineHeight="1.2"
                                  fontWeight={400}
                                  color={palette.gray.darkest}
                                >
                                  Link to the service for reference, but
                                  brightbot can’t interact with it
                                </Typography>
                              </>
                            }
                          />
                          </RadioGroup>
                        </FormControl>
                      </RadioHolder> */}
                      <FormCenter>
                        {true && (
                          <>
                            <Search
                              options={options}
                              setFieldValue={setFieldValue}
                              values={values}
                              resetForm={resetForm}
                            />

                            {values.provider && (
                              <Fields
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                touched={touched}
                              />
                            )}
                          </>
                        )}
                      </FormCenter>
                    </PanelTopContent>
                    <FooterBtnHolder>
                      <Button onClick={() => setOpen(false)} variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading || !isValid || !values.provider}
                        type="submit"
                      >
                        Add Service
                      </Button>
                    </FooterBtnHolder>
                  </PanelContent>
                </form>
              )}
            </Formik>
          </PanelHolder>
        </RightSidePanel>
      )}
    </>
  );
};
