import { Box, FormHelperText, styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const StyledContanier = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)(({}) => ({
  width: "13.33px",
  height: "13.33px",
  marginTop: "4px",
  marginRight: "5px",
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 400,
}));
