import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetCurrentUserInfoDocument,
  GetProjectManagerCandidatesDocument,
  GetProjectManagersDocument,
  UpdateProjectDocument,
  UpdateProjectNewDocument,
  UserOutput,
} from "../../generated";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProfileAvatarIcon from "../ProfileAvatarIcon/ProfileAvatarIcon";
import { Label } from "../Label/Label";
import { toast } from "react-toastify";
import { BtnsWrap, ButtonDefault, ButtonPrimary, MemberItem } from "./style";
import { ProjectOverviewDeleteManager } from "../../Projects/ProjectOverviewPage/ProjectOverviewDeleteManager";

export const ProjectManagers = ({
  isEditable,
  projectManagers,
  refetch,
}: {
  isEditable?: boolean;
  projectManagers: any;
  refetch: any;
}) => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const [memberId, setMemberId] = useState<string[]>();
  const [editManagers, setEditManagers] = useState(false);

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  const { data } = useQuery(GetProjectManagerCandidatesDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
        projectId: projectId || "",
      },
    },
    skip: isEditable === false || isEditable === undefined,
  });

  const [updateManager] = useMutation(UpdateProjectNewDocument);

  const managers = data?.projectManagerCandidates.filter(
    (itemA) => !projectManagers.some((itemB: any) => itemA.id === itemB.id)
  );

  const submitHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (memberId) {
      updateManager({
        variables: {
          input: {
            workspaceId: workspaceId || "",
            projectId: projectId || "",
            managers: {
              connect: memberId || [],
              disconnect: [],
            },
          },
        },
      }).then((res) => {
        if (res.data) {
          toast.success("User added");
          refetch();
          setEditManagers(false);
        } else {
          toast.error("User can't be added");
        }
      });
    }
  };

  return (
    <Box
      sx={{
        mb: 4,
      }}
    >
      <Box>
        <Label
          value="Project Manager"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 500,
            color: palette.common.black,
            mb: 1,
          }}
          info
        />
        {isEditable && (
          <>
            {!editManagers && (
              <IconButton
                // disabled={loadingMutate}
                onClick={() => setEditManagers(true)}
                sx={{
                  marginLeft: "10px",
                  marginTop: "-3px",
                }}
              >
                <EditOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            )}
          </>
        )}
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {isEditable && (
          <>
            {editManagers && (
              <Box
                sx={{
                  mb: 3,
                  width: "100%",
                }}
              >
                <form onSubmit={submitHandler}>
                  <Autocomplete
                    multiple
                    id="add-member"
                    options={managers?.filter((manager) => manager) || []}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, value: any) =>
                      setMemberId(value.map((memeber: any) => memeber.id))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Managers"
                      />
                    )}
                  />
                  <BtnsWrap>
                    <ButtonPrimary type="submit" id="add-member">
                      Add
                    </ButtonPrimary>
                    <ButtonDefault onClick={() => setEditManagers(false)}>
                      Cancel
                    </ButtonDefault>
                  </BtnsWrap>
                </form>
              </Box>
            )}
          </>
        )}

        <Box display="flex" alignItems="center" flexWrap="wrap" flex={1}>
          {projectManagers?.map((manager: UserOutput) => (
            <MemberItem>
              <ProfileAvatarIcon
                name={`${manager?.firstName} ${manager?.lastName}`}
                width={24}
                height={24}
              />
              <Typography
                variant="body2"
                sx={{ color: palette.gray.darkest }}
                ml={1}
              >
                {`${manager?.firstName} ${manager?.lastName}`}
              </Typography>
              {userInfo?.currentUser.id !== manager.id && !editManagers && (
                <ProjectOverviewDeleteManager
                  id={manager.id}
                  refetch={refetch}
                />
              )}
            </MemberItem>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
