import { Box, Divider } from "@mui/material";
import { Typography } from "../../Typography/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import { DOCLink, Link } from "../../Link/Link";
import { IconCell } from "../CellRenderer/IconCell";
import { Tag } from "../../Tag/Tag";
import SyncIcon from "@mui/icons-material/Sync";
import { Button } from "../../Button/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { CopyInput } from "../../CopyInput/CopyInput";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { TransformationServiceOutput } from "../../../generated";
import { convertToTitleCase } from "../../../helpers";
import ReactJson from "react-json-view";

export const ServiceConfiguration = ({
  data,
}: {
  data?: TransformationServiceOutput;
}) => {
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [editView, setEditView] = useState(false);

  return (
    <Box>
      <Box>
        <Typography gutterBottom variant="subtitle3">
          Description
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          {data?.description}
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Service owner
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <BusinessIcon color="disabled" fontSize="small" />{" "}
            {data?.owner.name}
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Service Manager
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          {data?.manager?.firstName + " " + data?.manager?.lastName}
        </Typography>

        {data?.manager?.emailAddress && (
          <Typography
            variant="body2"
            color="gray.darkest"
            sx={{
              fontWeight: "bold",
              a: {
                color: "link.main",
                textDecoration: "none",
              },
            }}
          >
            <a href={`mailto:${data?.manager?.emailAddress}`}>
              {data?.manager?.emailAddress}
            </a>
          </Typography>
        )}
      </Box>

      <Box mt={5}>
        <Divider />
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Service type
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          <Box display="flex" gap={2} alignItems="center">
            <IconCell value={data?.provider || ""} />{" "}
            {convertToTitleCase(data?.provider.replaceAll("_", " "))}
          </Box>
        </Typography>
      </Box>

      <Box mt={5}>
        <Button
          variant="outlined"
          size="small"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
          startIcon={
            <KeyboardArrowRightIcon
              style={{
                transition: "0.3s",
                transform: editView ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          }
          onClick={() => setEditView(!editView)}
        >
          Show configuration details
        </Button>
        {data && editView && (
          <Box
            mt={5}
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.25",
            }}
          >
            <ReactJson
              src={
                { configuration: JSON.parse(data?.configurations || "") } || {}
              }
              enableClipboard={(e) =>
                navigator.clipboard.writeText(
                  JSON.stringify({ [e?.name || ""]: e.src })
                )
              }
            />
          </Box>
        )}
      </Box>

      {(data?.jobCount && (
        <Box mt={5}>
          <Typography gutterBottom variant="subtitle3">
            # Jobs
          </Typography>
          <Typography variant="body2" color="gray.darkest">
            {data?.jobCount}
          </Typography>
        </Box>
      )) ||
        ""}

      {data?.languages && (
        <Box mt={5}>
          <Typography gutterBottom variant="subtitle3">
            Languages
          </Typography>
          <Box display="flex" gap={2}>
            {data?.languages?.map((language) => (
              <Tag label={language} />
            ))}
          </Box>
        </Box>
      )}

      <Box mt={5}>
        <Divider />
      </Box>

      <Box mt={5}>
        <Typography gutterBottom variant="subtitle3">
          Last Updated
        </Typography>
        <Typography variant="body2" color="gray.darkest">
          {data?.modifiedAt}
        </Typography>
      </Box>
    </Box>
  );
};
