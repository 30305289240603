import {
  styled,
  TextField,
  Box,
  FormHelperText,
  Typography,
  Button,
  Tab,
  FormLabel,
} from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const TextFieldCustom = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    width: 100%;
    height: 36px;
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
    box-sizing: border-box;
    background: white;
  }
`;
export const ErrorHolder = styled(Box)`
  display: flex;
  margin-top: .25rem;
  justify-Content: flex-start;
  align-items: center;
  svg {
    color: ${brighthiveTheme.palette.error.dark};
    width: 13.33px;
    height: 13.33px;
    margin-top: 4px;
    margin-right: 5px;
`;
export const FormHelperContent = styled(FormHelperText)`
  color: ${brighthiveTheme.palette.error.dark};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
