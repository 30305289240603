import * as Yup from "yup";

export const userDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
});

export const userPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string()
    .required()
    .min(8)
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&?_=*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password doesn't meet the requirements."
    ),
  confirmNewPassword: Yup.string()
    .required()
    .test("passwords-match", "Passwords are not matching", function (value) {
      return this.parent.newPassword === value;
    }),
});
