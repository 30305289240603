import { useEffect, useState } from "react";
import { displayToast } from "../../common/Toast/toast";
import { useMutation } from "@apollo/client";
import { ForgotPasswordDocument } from "../../generated";

type Props = {
  userEmail: string;
};

const useUserPassword = ({ userEmail }: Props) => {
  const [forgotPassword] = useMutation(ForgotPasswordDocument);
  const [displayEmailConfirmation, setDisplayEmailConfirmation] =
    useState(false);
  const [togglePasswordEdit, setTogglePasswordEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const handleTogglePassword = (toggle: boolean) => {
    setTogglePasswordEdit(toggle);
  };

  useEffect(() => {
    // handles the persistance of the resend counter
    const savedTimestamp = localStorage.getItem("resendTimestamp");
    if (savedTimestamp) {
      const elapsedSeconds = Math.floor(
        (Date.now() - parseInt(savedTimestamp, 10)) / 1000
      );
      const remainingTime = 30 - elapsedSeconds;

      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
        setIsDisabled(true);
      } else {
        localStorage.removeItem("resendTimestamp"); // Clear expired timestamp
      }
    }

    let timer: NodeJS.Timeout;
    if (isDisabled) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsDisabled(false);
            localStorage.removeItem("resendTimestamp"); // Clear timestamp when done
            return 30;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isDisabled]);

  useEffect(() => {
    // handles the display of the sent email message for 30 seconds then removes it
    let timeout: NodeJS.Timeout;
    if (displayEmailConfirmation) {
      timeout = setTimeout(() => {
        setDisplayEmailConfirmation(false);
      }, 30000);
    }
    return () => clearTimeout(timeout);
  }, [displayEmailConfirmation]);

  const submitUserPassword = async () => {
    try {
      handleTogglePassword(false);
      displayToast("Updated Details Successfully");
    } catch (err) {
      displayToast("Something went wrong", {}, "error");
      console.log(err);
    }
  };

  const changePasswordHandler = async () => {
    try {
      setPasswordLoading(true);
      await forgotPassword({
        variables: {
          input: { username: userEmail },
        },
      });
      const timestamp = Date.now();
      localStorage.setItem("resendTimestamp", timestamp.toString()); // Save the start time
      setIsDisabled(true);
      setTimeLeft(30);
      setDisplayEmailConfirmation(true);
    } catch (err) {
      displayToast(`something went wrong`, {}, "error");
    } finally {
      setPasswordLoading(false);
    }
  };

  const toggleDisplayEmailConfirmation = (toggle: boolean) =>
    setDisplayEmailConfirmation(toggle);

  return {
    togglePasswordEdit,
    handleTogglePassword,
    submitUserPassword,
    changePasswordHandler,
    loadingMutatePassword: passwordLoading,
    changePassTimeLeft: timeLeft,
    changePassIsDisabled: isDisabled,
    displayEmailConfirmation,
    toggleDisplayEmailConfirmation,
  };
};

export default useUserPassword;
