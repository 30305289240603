import { Box, TextField, useTheme, colors } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TextFieldCustom, ErrorHolder, FormHelperContent } from "./style";

interface CustomTextFieldProps {
  name?: string;
  value: string;
  onChange: any;
  error?: string;
  touched?: boolean;
  type?: string;
  showErrorText?: boolean;
  onBlur?: any;
  id?: any;
}

export const CustomTextField = ({
  name,
  value,
  onChange,
  error,
  touched,
  type,
  showErrorText,
  onBlur,
  id,
}: CustomTextFieldProps) => {
  const { palette } = useTheme();
  return (
    <>
      <TextFieldCustom
        onBlur={onBlur}
        name={name}
        value={value}
        error={touched && error ? true : false}
        onChange={onChange}
        type={type}
        id={id}
      />
      {showErrorText && touched && error && (
        <ErrorHolder>
          <ErrorOutlineIcon />
          <FormHelperContent>{error}</FormHelperContent>
        </ErrorHolder>
      )}
    </>
  );
};
CustomTextField.defaultProps = {
  showErrorText: true,
};
