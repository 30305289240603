import {
  Autocomplete,
  FormControl,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";

import {
  SubHeading,
  ListIconText,
  ListingHead,
  ListingHeadBtn,
  ListRow,
  ListText,
  Label,
} from "../style";
export const Search = ({ setFieldValue, values, options, resetForm }: any) => {
  const { palette } = useTheme();

  const handleSelectChange = (event: any, newValue: any) => {
    resetForm();
    setFieldValue("provider", newValue?.value);
  };

  return (
    <FormControl>
      <Label htmlFor="search">Available Services</Label>
      <Autocomplete
        id="search"
        className="custom-autocomplete"
        multiple={false}
        options={options}
        getOptionLabel={(option: any) => option?.title}
        value={options.find((op: any) => op.value === values.provider)}
        onChange={handleSelectChange}
        renderInput={(params: any) => (
          <TextField {...params} placeholder="Find Template" />
        )}
        classes={{ popper: "custom-autocomplete-dropdown" }}
        renderOption={(props, option: any) => (
          <MenuItem {...props} key={option} value={option.value}>
            <ListingHead>
              <ListIconText>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.7967 21.2024V14.3022L8.20215 18.6854V21.2024C8.2023 21.5864 8.05281 21.9553 7.78541 22.2307C7.51803 22.5063 7.15378 22.6667 6.77002 22.678H17.2722C16.8809 22.678 16.5056 22.5226 16.2289 22.2458C15.9522 21.9691 15.7967 21.5938 15.7967 21.2024Z"
                    fill="#A1D2AB"
                  />
                  <path
                    d="M8.20211 2.97551V9.87573L15.7966 5.49257V2.97551C15.7966 2.58418 15.9521 2.20889 16.2288 1.93217C16.5055 1.65546 16.8809 1.5 17.2721 1.5H6.72656C7.11789 1.5 7.4932 1.65546 7.76991 1.93217C8.04658 2.20889 8.20211 2.58418 8.20211 2.97551Z"
                    fill="#FEBE2D"
                  />
                  <path
                    d="M18.0101 4.23407L15.7969 5.49261V14.3023L21.8291 10.8305C22.1639 10.6392 22.5603 10.5867 22.9334 10.684C23.3065 10.7813 23.6267 11.0208 23.8254 11.3513L18.5309 2.23779C18.6314 2.40209 18.6977 2.58497 18.7259 2.77551C18.7541 2.96604 18.7436 3.16029 18.695 3.34666C18.6464 3.53303 18.5607 3.70769 18.443 3.86017C18.3253 4.01264 18.1781 4.13981 18.0101 4.23407Z"
                    fill="#5BCBF5"
                  />
                  <path
                    d="M23.8254 12.7832C23.9493 12.5734 24.0095 12.3322 23.9989 12.0888C24.0084 11.8318 23.9484 11.5769 23.8254 11.351C23.6267 11.0206 23.3065 10.7811 22.9334 10.6838C22.5603 10.5864 22.1639 10.6391 21.8291 10.8302L15.7969 14.3021V21.2023C15.7969 21.5936 15.9523 21.9689 16.229 22.2456C16.5057 22.5223 16.881 22.6778 17.2724 22.6778H17.663L18.0535 22.5042L18.3139 22.3306V22.2438L18.4875 22.0268L22.6971 14.6927L23.782 12.8699L23.8254 12.7832Z"
                    fill="#15B9A8"
                  />
                  <path
                    d="M15.7969 2.97533V5.49239L18.0101 4.23386C18.1781 4.13959 18.3253 4.01243 18.443 3.85995C18.5607 3.70747 18.6464 3.53281 18.695 3.34645C18.7436 3.16007 18.7541 2.96582 18.7259 2.77529C18.6977 2.58476 18.6314 2.40187 18.5309 2.23758C18.4842 2.12027 18.4097 2.01602 18.3139 1.93379C18.0397 1.68663 17.6849 1.54779 17.3158 1.54321H17.2724C16.8884 1.54305 16.5195 1.69252 16.2441 1.95992C15.9685 2.22731 15.8081 2.59156 15.7969 2.97533Z"
                    fill="#89A150"
                  />
                  <path
                    d="M5.98915 19.944L8.20241 18.6854V9.87573L2.21356 13.3475C1.87875 13.5388 1.48238 13.5914 1.10928 13.4941C0.736182 13.3967 0.416011 13.1571 0.217285 12.8267L5.46838 21.9402C5.27714 21.6054 5.22456 21.209 5.32188 20.8359C5.41922 20.4629 5.65872 20.1427 5.98915 19.944Z"
                    fill="#F6A7C0"
                  />
                  <path
                    d="M8.20224 21.2024V18.6853L5.98898 19.9438C5.65855 20.1426 5.41904 20.4627 5.32171 20.8358C5.22438 21.2089 5.27696 21.6053 5.46821 21.9401L5.6852 22.2439H5.7286C5.8608 22.3719 6.01711 22.4723 6.18846 22.5394C6.35981 22.6064 6.54279 22.6387 6.72674 22.6345H6.77014C7.1465 22.6236 7.50446 22.4692 7.77072 22.2029C8.03691 21.9367 8.19131 21.5787 8.20224 21.2024Z"
                    fill="#A79087"
                  />
                  <path
                    d="M2.21327 13.3475L8.20214 9.87569V2.97551C8.20214 2.58419 8.04669 2.20889 7.76994 1.93217C7.49325 1.65546 7.11794 1.5 6.72662 1.5H6.33603C6.20869 1.53091 6.08999 1.59026 5.98885 1.67359L5.72847 1.84717C5.68507 1.89057 5.68507 1.89058 5.68507 1.93398L5.51148 2.15096H5.46808L1.25853 9.44175L0.173588 11.2644C0.0704029 11.4821 0.0114049 11.7182 0 11.9588C0.0058474 12.2196 0.0807195 12.4742 0.216988 12.6966C0.304386 12.8712 0.425592 13.0268 0.573585 13.1543C0.721579 13.2818 0.893408 13.3786 1.07911 13.4391C1.26482 13.4997 1.46069 13.5228 1.65538 13.5071C1.85007 13.4914 2.0397 13.4371 2.21327 13.3475Z"
                    fill="#F5843C"
                  />
                </svg>
                {option.title}
              </ListIconText>
              <ListingHeadBtn>
                {values.provider === option ? "Selected" : "Select"}
              </ListingHeadBtn>
            </ListingHead>
            <ListRow>
              <ListIconText className="add">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14 7.33333V5.33333C14 4.97971 13.8595 4.64057 13.6095 4.39052C13.3594 4.14048 13.0203 4 12.6667 4H8.66667M8.66667 4L10.6667 6M8.66667 4L10.6667 2M2 8.66667V10.6667C2 11.0203 2.14048 11.3594 2.39052 11.6095C2.64057 11.8595 2.97971 12 3.33333 12H7.33333M7.33333 12L5.33333 10M7.33333 12L5.33333 14M2 4C2 4.53043 2.21071 5.03914 2.58579 5.41421C2.96086 5.78929 3.46957 6 4 6C4.53043 6 5.03914 5.78929 5.41421 5.41421C5.78929 5.03914 6 4.53043 6 4C6 3.46957 5.78929 2.96086 5.41421 2.58579C5.03914 2.21071 4.53043 2 4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4ZM10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12Z"
                    stroke="#7E8AA0"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Method:
              </ListIconText>
              <ListText>{option.method}</ListText>
            </ListRow>
            <ListRow>
              <ListIconText className="add">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_8479_8541)">
                    <path
                      d="M2 14H14"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 5.33325H6.66667"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 8H6.66667"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 10.6667H6.66667"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 5.33325H10.0002"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 8H10.0002"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 10.6667H10.0002"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.3335 14V3.33333C3.3335 2.97971 3.47397 2.64057 3.72402 2.39052C3.97407 2.14048 4.31321 2 4.66683 2H11.3335C11.6871 2 12.0263 2.14048 12.2763 2.39052C12.5264 2.64057 12.6668 2.97971 12.6668 3.33333V14"
                      stroke="#7E8AA0"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8479_8541">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Owner:
              </ListIconText>
              <ListText>{option.owner}</ListText>
            </ListRow>
            <ListText>{option.description}</ListText>
          </MenuItem>
        )}
      />
    </FormControl>
  );
};
