import {
  alpha,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  styled,
} from "@mui/material";
import React from "react";
import merge from "lodash/merge";

export type ButtonProps = MUIButtonProps;

export const ButtonModified = (props: ButtonProps) => {
  // const theme = useTheme();
  // const { isFocusVisible } = useFocusVisible();
  //
  // const outlineColor = props.color === "grayAccent" || props.color === "inherit" || props.color === undefined ?
  //   theme.palette.primary.main :
  //   theme.palette[props.color].main;
  //
  // const baseStyling: CSSProperties = {
  //   display: 'inline-block',
  // };
  //
  // const outlineStyle: CSSProperties = isFocusVisible ?
  //   {
  //     ...baseStyling,
  //     outline: `${outlineColor} solid .1875rem`,
  //     outlineOffset: 3,
  //   } : { ...baseStyling };

  return <MUIButton disableFocusRipple {...props} />;
};

export const Button = styled(ButtonModified)(
  ({ theme, variant, color, disabled, size }) => {
    const { palette }: any = theme;

    if (!color) {
      color = "primary";
    }

    const boxShadow =
      `0rem .125rem .125rem ${palette.gray.main}, ` +
      `0rem .1875rem .0625rem ${palette.action.disabledBackground}, ` +
      `0rem .0625rem .3125rem ${palette.disabledDarkText};`;

    const outlineColor =
      color === "grayAccent" || color === "inherit" || color === undefined
        ? palette.primary.darkest
        : palette[color].main;

    let styles = {
      "&:focus-visible": {
        outline: `${outlineColor} solid .1875rem`,
        outlineOffset: ".1875rem",
      },
      color: palette.primary.darkest,
      "text-transform": "none",
      padding: ".5rem .75rem",
      boxShadow: "none",
      borderRadius: ".1875rem",
      ".MuiButton-startIcon > *:nth-of-type(1)": {
        fontSize: "1rem",
      },
      ".MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: ".25rem",
      },
      ".MuiButton-endIcon > *:nth-of-type(1)": {
        fontSize: "1rem",
      },
      ".MuiButton-endIcon": {
        marginLeft: ".25rem",
        marginRight: 0,
      },
      ".MuiChip-root.Mui-disabled": {
        opacity: 1,
        backgroundColor: palette.disabledDarkText,
      },
    };

    // Contained styling
    if (variant === "contained" && color !== "inherit" && color !== undefined) {
      styles = merge(styles, {
        color: palette.primary.darkest,
        "&:hover": {
          backgroundColor: palette[color].dark,
          color: palette.common.white,
          boxShadow: "none",
        },
        "&:focus-visible": {
          backgroundColor: palette[color].dark,
          color: palette.common.white,
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: palette[color].dark,
          color: palette.common.white,
          boxShadow: "none",
        },
      });
    }
    // Outlined And text styling
    if (variant === "outlined") {
      styles = merge(styles, {
        border: `.0625rem solid ${palette.outline} !important`,
      });
    }
    if (
      (variant === "outlined" || variant === "text") &&
      color === "grayAccent"
    ) {
      styles = merge(styles, {
        "&:hover": {
          backgroundColor: alpha(palette.grayAccent.main, 0.06),
          color: palette.grayAccent.dark,
        },
        "&:active": {
          backgroundColor: alpha(palette.grayAccent.main, 0.16),
          color: palette.grayAccent.dark,
        },
      });
    }
    if (variant === "contained" && color === "grayAccent") {
      styles = merge(styles, {
        color: palette.common.white,
      });
    }
    if (
      (variant === "outlined" || variant === "text") &&
      color !== "inherit" &&
      color !== undefined &&
      color !== "grayAccent"
    ) {
      styles = merge(styles, {
        "&:hover": {
          color: palette[color].dark,
        },
        "&:active": {
          backgroundColor: alpha(
            palette[color].main,
            palette.action.activatedOpacity
          ),
          color: palette[color].dark,
        },
      });
    }
    if (disabled && variant === "outlined") {
      styles = merge(styles, {
        backgroundColor: palette.disabledLightBackground,
        color: palette.disabledLightTextOnDarkBackground,
      });
    }
    if (disabled && variant === "text") {
      styles = merge(styles, {
        color: palette.disabledLightTextOnDarkBackground,
      });
    }
    return styles;
  }
);
