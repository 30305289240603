import { styled, Box, Typography, Button, Tab, FormLabel } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";
import { Tabs } from "../../common/Tabs/Tabs";

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-indicator {
    height: 2px;
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover {
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;
export const PanelHolder = styled(Box)`
  position: relative;
`;
export const PanelContent = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 48px);
`;
export const PanelTopContent = styled(Box)`
  flex: 1;
`;
export const PanelContentHead = styled(Box)`
  margin-bottom: 24px;
  padding-right: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
`;
export const HeadSmallTitle = styled(Typography)`
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${brighthiveTheme.palette.gray.dark};
`;
export const HeadTitle = styled(Typography)`
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};
  &.mt {
    padding-top: 20px;
  }
`;
export const SubHeading = styled(Typography)`
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};
  &.add {
    margin: 0 0 6px;
  }
`;
export const RadioHolder = styled(Box)`
  margin: 0 0 24px;
  padding: 0 0 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0 0 12px -16px;
  }
  .MuiRadio-root {
    padding-top: 0;
  }
  .Mui-checked + span .label-title {
    font-weight: 500;
  }
  .Mui-checked + span .label-title {
    font-weight: 500;
  }
  .formlist {
    margin: 0 0 12px -13px;
  }
`;
export const LabelTitle = styled(Typography)`
  margin: 0 0 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};
`;
export const LableDes = styled(Typography)`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${brighthiveTheme.palette.gray.darkest};
`;
export const FormCenter = styled(Box)`
  margin: 0 0 24px;
  padding: 0 0 12px;
  display: flex;
  flex-direction: column;
`;
export const ListingHead = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 0 12px;
`;
export const ListingHeadBtn = styled(Box)`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 6px;
  border-radius: 2px;
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  color: ${brighthiveTheme.palette.primary.dark};
`;
export const ListIconText = styled(Box)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${brighthiveTheme.palette.common.black};
  display: flex;
  align-items: center;
  svg {
    margin: 0 16px 0 0;
  }
  &.add {
    font-size: 14px;
    line-height: 18px;
    svg {
      margin: 0 3px 0 0;
    }
  }
`;
export const ListRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 8px;
`;
export const ListText = styled(Typography)`
  font-size: 14px;
  white-space: normal;
  line-height: 24px;
  color: ${brighthiveTheme.palette.common.black};
`;
export const FooterBtnHolder = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 24px;
  margin: 0 -24px -24px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const FieldsHolder = styled(Box)`
  padding: 20px 0 0;
`;
export const FieldsSet = styled(Box)`
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;

  .asterisk {
    color: ${brighthiveTheme.palette.error.main};
  }
`;
export const Label = styled(FormLabel)`
  margin: 0 0 6px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};
`;
