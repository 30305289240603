import { useMutation } from "@apollo/client";
import {
  UpdateProjectNewDocument,
  UpdateWorkspaceRoleScopeDocument,
} from "../../generated";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteIcon } from "../../common/Icons/DeleteIcon";
import { Button } from "@mui/material";
import { useState } from "react";

export const ProjectOverviewDeleteManager = ({ id, refetch }: any) => {
  const { workspaceId, projectId } = useParams();
  const [updateManager] = useMutation(UpdateProjectNewDocument);
  const [loading, setLoading] = useState(false);

  const deleteMember = (memberId: any) => {
    setLoading(true)
    if (memberId) {
      updateManager({
        variables: {
          input: {
            workspaceId: workspaceId || "",
            projectId: projectId || "",
            managers: {
              connect: [],
              disconnect: memberId || [],
            },
          },
        },
      }).then(async (res) => {
        if (res.data?.updateProject) {
          toast.success("User deleted");
          await refetch();
        } else {
          toast.error("User can't be deleted");
        }
      }).finally(() => setLoading(false))
    }
  };

  return (
    <Button  onClick={() => deleteMember(id)} disabled={loading}>
      <DeleteIcon />
    </Button>
  );
};
