import { TextFieldProps, useTheme } from "@mui/material";
import { StyledTextField } from "../../style";
import {
  StyledContanier,
  StyledErrorOutlineIcon,
  StyledFormHelperText,
} from "./style";

type Props = Omit<TextFieldProps, "error"> & {
  touched?: boolean;
  showErrorText?: boolean;
  error?: string | undefined;
};

const CustomValidationTextField = ({
  error,
  touched,
  showErrorText = true,
  ...props
}: Props) => {
  const { palette } = useTheme();
  return (
    <>
      <StyledTextField error={touched && error ? true : false} {...props} />
      {showErrorText && touched && error && (
        <StyledContanier mt={1}>
          <StyledErrorOutlineIcon htmlColor={palette.error.dark} />
          <StyledFormHelperText>{error}</StyledFormHelperText>
        </StyledContanier>
      )}
    </>
  );
};

export default CustomValidationTextField;
