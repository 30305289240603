import { Box } from "@mui/material";
import CustomValidationTextField from "../CustomValidationTextField";
import { useFormikContext } from "formik";
import { UserDetailsType } from "../../MyProfilePage";
import { ButtonDefault, ButtonPrimary } from "../../style";

type Props = {
  handleToggleDetailsEdit: (toggle: boolean) => void;
  loadingMutateDetails: boolean;
};

const UserDetailsEditForm = ({
  loadingMutateDetails,
  handleToggleDetailsEdit,
}: Props) => {
  const { values, errors, handleChange, touched, handleSubmit } =
    useFormikContext<UserDetailsType>();

  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <Box>
        <CustomValidationTextField
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          touched={touched.firstName}
          error={errors.firstName}
        />
      </Box>
      <Box>
        <CustomValidationTextField
          name="lastName"
          sx={{ marginLeft: "1rem" }}
          value={values.lastName}
          onChange={handleChange}
          touched={touched.lastName}
          error={errors.lastName}
        />
      </Box>
      <Box display="flex" ml={5} gap={3}>
        <ButtonDefault onClick={() => handleToggleDetailsEdit(false)}>
          Cancel
        </ButtonDefault>
        <ButtonPrimary
          disabled={loadingMutateDetails}
          onClick={() => handleSubmit()}
        >
          Update
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

export default UserDetailsEditForm;
