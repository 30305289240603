import { Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Typography } from "../../../common/Typography/Typography";
import { BasicPage } from "../../../common/BasicPage/BasicPage";

import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetSchemasDocument } from "../../../generated";
import { SchemaDetail } from "./SchemaDetails";
import { SchemaDetailBox } from "../style";

export interface ProjectSchemaEditDetailrops {}

export const SchemaDetailPage = ({
  isEditMode = false,
  schema,
  showBackBtn = true,
}: {
  isEditMode?: boolean;
  schema?: any;
  showBackBtn?: any;
}) => {
  const { workspaceId, schemaId: id } = useParams();
  const navigate = useNavigate();

  const schemaId = id || schema?.id;

  const { data, loading } = useQuery(GetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      schemaId: schemaId || "",
    },
    skip: schema,
  });

  const schemaDetail = data?.workspace?.schemas?.[0] || schema;

  return (
    <BasicPage
      sx={{
        ".right-elements": {
          alignSelf: "flex-end",
        },
      }}
      leftHeaderElements={
        <Box>
          {showBackBtn && (
            <Box mb={3}>
              <SchemaDetailBox onClick={() => navigate(-1)}>
                <ArrowBack className="btn-back" />
                <Typography variant="body3">Back</Typography>
              </SchemaDetailBox>
            </Box>
          )}
          <Box display="flex">
            <Typography mr={4} variant="h6">
              {schemaDetail?.name || ""}
            </Typography>
          </Box>
        </Box>
      }
      content={
        <SchemaDetail
          data={schemaDetail}
          loading={loading}
          isEditMode={isEditMode}
        />
      }
    />
  );
};

export {};
